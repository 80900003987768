<mat-list-item>
  <mat-icon mat-list-icon>tune</mat-icon>
  <h4 mat-line>{{'free_fields'|translate|ucFirst}}</h4>
</mat-list-item>

<ng-container *ngIf="companyFreeFieldSettings && companyFreeFieldSettings?.length > 0">
  <div [formGroup]="form" class="row-container">
    <div class="row-layout" layout="row" fxLayout="row wrap" fxLayoutGap="16px" formGroupName="foreignMeta">
      <ng-container *ngFor="let field of companyFreeFieldSettings">
        <ng-container *ngIf="!field.isDeleted && field.fieldType !== 'hidden'">

          <mat-list-item class="freefield-item" fxFlex="30" [style.borderColor]="field.color">
            <mat-form-field class="full-width" *ngIf="field.fieldType === 'text'">
              <mat-label>{{ field.name }}</mat-label>
              <input matInput [placeholder]="field.placeholder" [formControlName]="field.attributeName" type="text">
              <mat-hint>{{ field.helptext }}</mat-hint>
            </mat-form-field>

            <mat-form-field class="full-width" *ngIf="field.fieldType === 'number'">
              <mat-label>{{ field.name }}</mat-label>
              <input matInput type="number" [min]="field.min" [max]="field.max"
                     [placeholder]="field.placeholder" [formControlName]="field.attributeName">
              <mat-hint>{{ field.helptext }}</mat-hint>
            </mat-form-field>

            <mat-form-field class="full-width" *ngIf="field.fieldType === 'dropdown'">
              <mat-label>{{ field.name }}</mat-label>
              <mat-select [placeholder]="field.placeholder" [formControlName]="field.attributeName">
                <mat-option *ngFor="let option of field.options" [value]="option.label">
                  {{ option.label }}
                </mat-option>
              </mat-select>
              <mat-hint>{{ field.helptext }}</mat-hint>
            </mat-form-field>

            <mat-form-field class="full-width" *ngIf="field.fieldType === 'numberdropdown'">
              <mat-label>{{ field.name }}</mat-label>
              <mat-select [placeholder]="field.placeholder" [formControlName]="field.attributeName">
                <mat-option *ngFor="let i of getRange(field.max)" [value]="i">
                  {{ i }}
                </mat-option>
              </mat-select>
              <mat-hint>{{ field.helptext }}</mat-hint>
            </mat-form-field>

            <div class="checkbox-wrapper" *ngIf="field.fieldType === 'checkbox'">
              <mat-checkbox [formControlName]="field.attributeName">
                {{ field.placeholder || field.name }}
              </mat-checkbox>
            </div>
          </mat-list-item>
        </ng-container>

        <!-- Hidden Fields: stay silent -->
        <input *ngIf="field.fieldType === 'hidden'" type="hidden" [formControlName]="field.attributeName" />
      </ng-container>
    </div>
  </div>
</ng-container>
