import {Component, Input, OnInit} from '@angular/core';
import {Ride} from '../../../../../models/ride';

@Component({
  selector: 'app-ride-meta-data',
  templateUrl: './ride-meta-data.component.html',
  styleUrls: ['./ride-meta-data.component.scss']
})
export class RideMetaDataComponent implements OnInit {
  @Input() companyFreeFieldSettings!: any;
  @Input() ride!: Ride;

  constructor() { }

  ngOnInit(): void {
  }

}
