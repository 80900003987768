import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {TdDialogService} from '@covalent/core/dialogs';
import {TdLoadingService} from '@covalent/core/loading';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {LocationPolicy} from '../../../../../../models/tps/location-policy';
import {environment} from '../../../../../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {NavigationService} from '../../../../../../services/navigation.service';
import {LocationPolicyService} from '../../../../../../services/tps/location-policy.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Company} from '../../../../../../models/company';
import {UtilityService} from '../../../../../../services/utility.service';

@Component({
  selector: 'app-location-policy-upsert',
  templateUrl: './location-policy-upsert.component.html',
  styleUrls: ['./location-policy-upsert.component.scss'],
  providers: [LocationPolicyService]
})
export class LocationPolicyUpsertComponent implements OnInit, OnDestroy {

  loaderName = 'location-policy';
  routeSub: Subscription;
  translations: string[] = [];

  id: string;
  companyId: string;
  action: string;
  company: Company;
  policy: LocationPolicy = new LocationPolicy();

  form: UntypedFormGroup;
  formErrors: any = {
    name: '',
    departureAllowed: '',
    destinationAllowed: '',
    departureWarning: '',
    destinationWarning: '',
  };
  validationMessages = {
    'name': {
      'required': 'Name is required',
    },
    'departureWarning': {
      'required': 'Departure warning is required',
    },
    'destinationWarning': {
      'required': 'Destination warning is required',
    },
  };

  constructor(public snackBar: MatSnackBar,
              private _route: ActivatedRoute,
              private _formBuilder: UntypedFormBuilder,
              private _titleService: Title,
              private _locationPolicyService: LocationPolicyService,
              private _dialogService: TdDialogService,
              private _navigationService: NavigationService,
              private _router: Router,
              private _translateService: TranslateService,
              private _loadingService: TdLoadingService) {
    this._navigationService.setActiveSubmenu(this._route.routeConfig['submenu']);

    this.routeSub = this._route.parent.params.subscribe(parentParams => {
      this.companyId = parentParams['id'];
      const {company} = this._route.parent.snapshot.data;
      UtilityService.setBrowserTimeStamp(company);
      this.company = company;
      this.routeSub = this._route.params.subscribe(params => {
        this.id = params['id'];
        this.action = params['action'] ? params['action'] : params['id'];

        _translateService.get([
          `${this.action}_location_policy`,
          'location_policy_added_message',
          'location_policy_added_title',
          'location_policy_updated_message'
        ]).subscribe((translations: any) => {
          this.translations = translations;
          this._titleService.setTitle(translations[`${this.action}_location_policy`] + environment.windowTitleSuffix);
          this._navigationService.setBodyTitle(translations[`${this.action}_location_policy`]);
        });

        if (this.action === 'edit') {
          this.loadData();
        }
      });
    });
  }

  ngOnInit(): void {
    if (this.action !== 'add') {
      this._loadingService.register(this.loaderName);
    }

    /**
     * Set up our form
     */
    this.form = this._formBuilder.group({
      name: [this.policy.name, [Validators.required]],
      departureAllowed: [this.policy.departureAllowed, []],
      departureBlocked: [this.policy.departureAllowed, []],
      destinationAllowed: [this.policy.destinationAllowed, []],
      destinationBlocked: [this.policy.destinationAllowed, []],
      departureWarning: [this.policy.departureWarning, [Validators.required]],
      destinationWarning: [this.policy.destinationWarning, [Validators.required]],
    });

    /**
     * Subscribe to value changes in the form
     */
    this.form.valueChanges.subscribe(data => this.onValueChanged(data));

    /**
     * (re)set validation messages now
     */
    this.onValueChanged();
  }

  ngOnDestroy(): void {
    this.routeSub.unsubscribe();
  }

  onValueChanged(data?: any) {
    if (!this.form) {
      return;
    }
    const form = this.form;
    for (const field in this.formErrors) {
      /**
       * clear previous error message (if any)
       */
      this.formErrors[field] = '';
      const control = form.get(field);
      /**
       * Loop through the fields and check for errors, set messages accordingly
       */
      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];
        for (const key in control.errors) {
          this.formErrors[field] += messages[key] + ' ';
        }
      }
    }
  }

  loadData(): void {
    this._locationPolicyService.get(this.id, {}).subscribe(policy => {
      this.policy = policy;
      this.form.patchValue({
        name: policy.name,
        departureAllowed: policy.departureAllowed,
        departureBlocked: policy.departureBlocked,
        destinationAllowed: policy.destinationAllowed,
        departureWarning: policy.departureWarning,
        destinationBlocked: policy.destinationBlocked,
        destinationWarning: policy.destinationWarning,
      });
      this._loadingService.resolve(this.loaderName);
    }, error => {
      console.error(error);
      this._loadingService.resolve(this.loaderName);
    })
  }

  save(): void {
    console.log(this.action.toLowerCase());
    const self = this;
    const data = this.form.value;
    const policy = this.policy;

    delete policy._id;
    delete policy.modified;
    delete policy.created;

    for (const key in data) {
      policy[key] = data[key];
    }

    policy.companyId = this.companyId;

    policy.departureBlocked = !(policy.departureAllowed);
    policy.destinationBlocked = !(policy.destinationAllowed);

    /**
     * Call the API
     */

    if (this.action.toLowerCase() === 'add') {
      this._loadingService.register(this.loaderName);
      this._locationPolicyService.insert(policy).subscribe(() => {
        setTimeout(function () {
          self._dialogService.openAlert({
            message: self.translations['location_policy_added_message'],
            disableClose: true,
            title: self.translations['location_policy_added_title'],
            closeButton: 'OK'
          }).afterClosed().subscribe(() => {
            self._router.navigate([`/${(self.company.type === 'business' ? 'groups' : 'dispatchgroup')}/${self.companyId}/location-policies`]);
          });
        }, 500);
      }, error => {
        console.error(error);
        this._loadingService.resolve(this.loaderName);
      })
    } else {
      this._locationPolicyService.update(this.id, policy).subscribe(() => {
        setTimeout(function () {
          self._loadingService.resolve('driver');
          const notification = self.snackBar.open(self.translations['location_policy_updated_message'], 'OK', {
            duration: 3000
          });
        }, 500);
      }, error => {
        console.error(error);
        this._loadingService.resolve(this.loaderName);
      })
    }
  }

  delete() {
    const self = this;
    self._dialogService.openConfirm({
      message: self._translateService.instant('message_delete_x').formatUnicorn(this.policy.name),
      disableClose: false,
      title: self._translateService.instant('confirm'),
      cancelButton: 'Cancel',
      acceptButton: 'Delete',
    }).afterClosed().subscribe((accept: boolean) => {
      if (accept) {
        this._locationPolicyService.delete(this.id).subscribe(() => {
          self._router.navigate([`/${(this.company.type === 'business' ? 'groups' : 'dispatchgroup')}/${this.companyId}/location-policies`]);
        }, error => {
          console.error(error);
        });
      }
    });
  }
}
