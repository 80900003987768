<mat-card [mediaClasses]="['push-sm']" tdMediaToggle="gt-xs">
  <mat-card-content class="push-bottom-none">
    <ng-template tdLoading="location-policy">
      <form *ngIf="form" [formGroup]="form">
        <div layout="row">
          <h3 class="push-top-none">{{ (data.name ? data.name : 'job_field_name'|translate|ucFirst) }}</h3>
        </div>

        <div flex="50" layout="row">
          <mat-form-field flex>
            <input (change)="(formErrors.name ? validateFormField(form, 'name') : '')"
                   formControlName="name"
                   matInput
                   placeholder="{{'name'|translate|ucFirst}}"
                   type="text">
            <mat-hint align="start" class="warning">
              <span [hidden]="!formErrors.name" class="tc-red-600">
                <span>{{ formErrors.name }}</span>
              </span>
            </mat-hint>
          </mat-form-field>
        </div>

        <div flex="50" layout="row">
          <mat-form-field [ngStyle]="{'width': '100%'}" flex>
            <mat-select class="row-select" formControlName="fieldType"
                        (selectionChange)="onFieldTypeChange($event.value)"
                        placeholder="{{'field_types'|translate|ucFirst}}">
              <mat-option *ngFor="let field of fieldTypes"
                          [value]="field">{{ field + '_meta_field' | translate | ucFirst }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div flex="50" layout="row">
          <mat-form-field flex>
            <input (change)="(formErrors.name ? validateFormField(form, 'name') : '')"
                   formControlName="placeholder"
                   matInput
                   placeholder="{{'placeholder'|translate|ucFirst}}"
                   type="text">
            <mat-hint align="start" class="warning">
              <span [hidden]="!formErrors.placeholder" class="tc-red-600">
                <span>{{ formErrors.placeholder }}</span>
              </span>
            </mat-hint>
          </mat-form-field>
        </div>

        <div flex="50" layout="row">
          <mat-form-field flex>
            <textarea [maxLength]="140" class="inputTextArea" formControlName="helptext" matInput ng-trim="false"
                      placeholder="{{'helptext'|translate|ucFirst}}"
                      rows="4"
                      type="text" value="{{data.helptext}}"></textarea>
          </mat-form-field>
        </div>

        <div flex="50" layout="row" class="push-top">
          <div class="color-example push-right" style="background-color:{{this.form.controls['color'].value}}"></div>
          <mat-form-field class="medium-input number-input" flex>
            <input (colorPickerChange)="updateMainColor($event)"
                   [(colorPicker)]="this.form.controls['color'].value"
                   [cpColorMode]="'color'"
                   [cpDialogDisplay]="'popup'"
                   [cpOKButton]="true"
                   [cpOutputFormat]="'hex'"
                   [cpPosition]="'bottom-right'"
                   flex="50"
                   formControlName="color"
                   matInput
                   placeholder="{{'webbooker_main_color'|translate|ucFirst}}"/>

            <mat-hint align="start" class="warning">
                  <span [hidden]="!formErrors.name" class="tc-red-600">
                    <span>{{ formErrors.name }}</span>
                  </span>
            </mat-hint>

          </mat-form-field>
        </div>

        <!-- Min/Max fields visible only for number & numberDropdown types -->
        <div *ngIf="form.controls['fieldType'].value === 'number' || form.controls['fieldType'].value === 'numberSelect'" flex="50" layout="column">
          <div [style.width.%]="100" class="left_form" flex="100">
            <mat-form-field [style.width.%]="50" class="left_form" flex="50">
              <input autocomplete="off" formControlName="min"
                     matInput
                     max="1000"
                     min="0"
                     placeholder="{{ 'allowed_minimum' | translate | ucFirst }}"
                     type="number">
            </mat-form-field>

            <mat-form-field [style.width.%]="50" class="right_form" flex="50">
              <input autocomplete="off" formControlName="max"
                     matInput
                     max="100"
                     min="0"
                     placeholder="{{ 'allowed_maximum' | translate | ucFirst }}"
                     type="number">
            </mat-form-field>
          </div>
        </div>

        <!-- Hidden field value input -->
        <div *ngIf="form.controls['fieldType'].value === 'hidden'" flex="50" layout="row">
          <mat-form-field flex>
            <input formControlName="hiddenValue" matInput placeholder="{{ 'hidden_field_value' | translate | ucFirst }}">
          </mat-form-field>
        </div>

        <div *ngIf="form.controls['fieldType'].value === 'number' || form.controls['fieldType'].value === 'numberSelect'" flex="50" layout="row">
          <div [style.width.%]="100" class="left_form" flex="100">
            <mat-form-field [style.width.%]="50" class="left_form" flex="50">
                <span matPrefix>{{ currencyDisplay ? currencyDisplay : currencySymbol }}&thinsp;</span>
                <input formControlName="surcharge" id="surcharge" matInput type="number" min="0" step="0.01" autocomplete="off"
                       (focus)="valOrZero($event.target); inputFocused($event)"
                       placeholder="{{ 'surcharge_per_amount' | translate | ucFirst }}">
            </mat-form-field>

            <mat-form-field [style.width.%]="50" class="right_form" flex="50">
              <span matPrefix>{{ currencyDisplay ? currencyDisplay : currencySymbol }}&thinsp;</span>
              <input formControlName="surchargeReturn" id="surchargeReturn" matInput type="number" min="0" step="0.01" autocomplete="off"
                     (focus)="valOrZero($event.target); inputFocused($event)"
                     placeholder="{{ 'surcharge_return_trip_per_amount' | translate | ucFirst }}">
            </mat-form-field>
          </div>
        </div>

        <!-- Options for Dropdown field type -->
        <div *ngIf="form.get('fieldType')?.value === 'dropdown'" formArrayName="options">
          <h3>{{ 'dropdown_options' | translate | ucFirst }}</h3>

          <div *ngFor="let option of options().controls; let i = index" [formGroupName]="i" layout="row" flex="100">
            <mat-form-field flex="40">
              <input formControlName="label" matInput placeholder="{{ 'option_label' | translate | ucFirst }}">
            </mat-form-field>

            <mat-form-field flex="25">
              <span matPrefix>{{ currencyDisplay || currencySymbol }}&thinsp;</span>
              <input formControlName="surcharge" matInput type="number" min="0" step="0.01"
                     placeholder="{{ 'surcharge' | translate | ucFirst }}">
            </mat-form-field>

            <mat-form-field flex="25">
              <span matPrefix>{{ currencyDisplay || currencySymbol }}&thinsp;</span>
              <input formControlName="surchargeReturn" matInput type="number" min="0" step="0.01"
                     placeholder="{{ 'surcharge_return_trip' | translate | ucFirst }}">
            </mat-form-field>

            <button mat-icon-button color="primary" (click)="addOption()">
              <mat-icon>add</mat-icon>
            </button>
            <button mat-icon-button color="warn"
                    (click)="removeOption(i)"
                    [style.visibility]="i > 0 ? 'visible' : 'hidden'">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>


        <mat-divider></mat-divider>

        <!-- Select SaAppInstalls -->
        <app-jobmetafield-connected-dapps  flex="100" layout="row"
                                        (updateCheckArrayFixed)="updateCheckArrayFixed($event)"
                                        *ngIf="form && translations && data"
                                        [form]="form"
                                        [jobMetaField]="this.data"
                                        [checkArrayFixed]="checkArrayFixed"
                                        [translations]="translations">
        </app-jobmetafield-connected-dapps>
      </form>
    </ng-template>
  </mat-card-content>
  <mat-divider></mat-divider>
  <mat-card-actions>
    <button (click)="save()"
            color="accent"
            flex
            mat-raised-button>{{ 'save_job_field_button'|translate|ucFirst }}
    </button>


    <button (click)="delete()"
            *ngIf="action !== 'add'"
            class="float-right"
            color="warn"
            flex
            mat-raised-button>{{'delete'|translate|ucFirst}}</button>
  </mat-card-actions>
</mat-card>
