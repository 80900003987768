import {Component, Input, OnInit} from '@angular/core';
import {FormGroup, UntypedFormGroup} from '@angular/forms';
import {Ride} from '../../../../../../../models/ride';

@Component({
  selector: 'app-ride-edit-freefields',
  templateUrl: './ride-edit-freefields.component.html',
  styleUrls: ['./ride-edit-freefields.component.scss']
})
export class RideEditFreefieldsComponent implements OnInit {
  @Input() context!: string;
  @Input() formErrors!: any;
  @Input() ride!: Ride;
  @Input() form!: UntypedFormGroup;
  @Input() companyFreeFieldSettings!: any;

  constructor() { }

  ngOnInit(): void {

  }

  getRange(max: number): number[] {
    return Array.from({ length: max + 1 }, (_, i) => i);
  }

}
