<mat-list-item>
  <mat-icon mat-list-icon>tune</mat-icon>
  <h4 mat-line>{{'free_fields' | translate | ucFirst}}</h4>
  <p mat-line>{{'ride_free_fields_subtitle' | translate | ucFirst}}</p>
</mat-list-item>

<div class="freefields-grid pull-top-sm push-bottom-sm" [mediaClasses]="['push-sm']" tdMediaToggle="gt-xs">
  <ng-container *ngFor="let field of companyFreeFieldSettings">
    <mat-list-item
      *ngIf="!field.isDeleted && ride?.foreignMeta?.[field.attributeName] !== undefined"
      class="value small"
    >
      <p mat-line>{{ field.name }}</p>
      <p mat-line>
        {{
          field.fieldType === 'checkbox'
            ? (ride.foreignMeta[field.attributeName] ? ('yes' | translate) : ('no' | translate))
            : ride.foreignMeta[field.attributeName] || '-'
        }}
      </p>
    </mat-list-item>
  </ng-container>
</div>
